import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Mekanisk diagnose og behandling (MDT)</h1>

      <p>
        MDT (Mekanisk diagnose og behandling) er en veletablert behandlingsform blant kiropraktorer og fysioterapeuter.
        MDT er både et verktøy som er meget effektivt på behandling av skiveskader, og som samtidig gir kiropraktoren
        tydelige indikasjoner på hvordan skiveskaden ser ut. Kiropraktoren får informasjon om det kan være små rifter i
        skiven, større rifter, skivebukninger eller prolapser. MDT forteller kiropraktoren mye om hvordan han skal
        justere ledd og hvilke typer hjemmeøvelser som egner seg best for akutte smerter. Dette er lette og effektive
        øvelser i den akutte smertefasen som pasienten kan utføre selv uten problemer.
      </p>
    </Layout>
  )
}

export default Page
